/**
 * Script custom for theme
 *
 * TABLE OF CONTENT
 *
 * Header: main menu
 * Header: main menu mobile
 * Sidebar: sticky sidebar
 * Feature: Preloading
 * Feature: Back to top
 * Custom select use Dropkickjs.
 */

(function ($) {
	"use strict";

	$(document).ready(function () {
		thim_onebusiness.ready();

		/* Search form menu right */
		$('.menu-right .button_search').on("click", function () {
			$('.menu-right .search-form').toggle(300);
			$('.menu-right input.search-field').focus();
		});

	});

	$(window).load(function () {
		thim_onebusiness.load();
	});

	var thim_onebusiness = {

		/**
		 * Call functions when document ready
		 */
		ready: function () {
			this.header_menu();
			this.back_to_top();
			this.feature_preloading();
			this.sticky_sidebar();
			this.contactform7();
			this.blog_auto_height();
			this.custom_select();
		},

		/**
		 * Call functions when window load.
		 */
		load: function () {
			this.header_menu_mobile();
			this.parallax();
			this.post_gallery();
			this.slider();
			this.magic_line();
			this.widget_masonry();
			this.widget_brands();
		},

		// CUSTOM FUNCTION IN BELOW
		post_gallery: function () {
			$('.flexslider').flexslider({
				slideshow     : true,
				animation     : 'fade',
				pauseOnHover  : true,
				animationSpeed: 400,
				smoothHeight  : true,
				directionNav  : true,
				controlNav    : false
			});
		},

		// CUSTOM SLIDER
		slider: function () {
			var rtl = false;
			if ($('body').hasClass('rtl')) {
				rtl = true;
			}

			$('.thim-slider .slides').owlCarousel({
				items: 1,
				nav  : true,
				dots : false,
				rtl  : rtl,
			});

			// scroll icon
			$('.thim-slider .scroll-icon').on('click', function () {
				var offset = $(this).offset().top;
				$('html,body').animate({scrollTop: offset + 80}, 800);
				return false;
			});

		},

		/**
		 * Mobile menu
		 */
		header_menu_mobile: function () {
			$(document).on('click', '.menu-mobile-effect', function (e) {
				e.stopPropagation();
				$('.responsive #wrapper-container').toggleClass('mobile-menu-open');
			});

			$(document).on('click', '.mobile-menu-open #main-content', function () {
				$('.responsive #wrapper-container.mobile-menu-open').removeClass('mobile-menu-open');
			});

			$('.navbar>li.menu-item-has-children >a.navbar-nav>li.menu-item-has-children >span').after('<span class="icon-toggle"><i class="fa fa-caret-down"></i></span>');
			$('.responsive .mobile-menu-container .navbar-nav>li.menu-item-has-children >a').after('<span class="icon-toggle"><i class="fa fa-caret-down"></i></span>');

			$('.responsive .mobile-menu-container .navbar-nav>li.menu-item-has-children .icon-toggle').on('click', function () {
				if ($(this).next('ul.sub-menu').is(':hidden')) {
					$(this).next('ul.sub-menu').slideDown(200, 'linear');
					$(this).html('<i class="fa fa-caret-up"></i>');
				} else {
					$(this).next('ul.sub-menu').slideUp(200, 'linear');
					$(this).html('<i class="fa fa-caret-down"></i>');
				}
			});
		},

		/**
		 * Magic line before menu item
		 * Khoapq
		 */
		magic_line: function () {
			var $el, leftPos, newWidth,
				$mainNav = $(".main-menu");

			$mainNav.append("<span id='magic-line'></span>");
			var $magicLine = $("#magic-line");
			var $current = $('.current-menu-item'),
				$current_a = $current.find('> a');

			if ($current.length <= 0) {
				return;
			}

			$magicLine
				.width($current_a.width())
				.css("left", $current.position().left + parseInt($current_a.css('padding-left')))
				.data("origLeft", $current.position().left + parseInt($current_a.css('padding-left')))
				.data("origWidth", $current_a.width());

			$(".main-menu > .menu-item").hover(function () {
				$el = $(this);
				leftPos = $el.position().left + parseInt($el.find('> a').css('padding-left'));
				newWidth = $el.find('> a').width();
				$magicLine.stop().animate({
					left : leftPos,
					width: newWidth
				});
			}, function () {
				$magicLine.stop().animate({
					left : $magicLine.data("origLeft"),
					width: $magicLine.data("origWidth")
				});
			});
		},

		/**
		 * Header menu sticky, scroll, v.v.
		 */
		header_menu: function () {
			var $header = $('#masthead.sticky-header'),
				$navigation = $('#masthead.sticky-header .navigation'),
				$header_v1 = $('#masthead.sticky-header .header-v1'),
				off_Top = ( $('#wrapper-container').length > 0 ) ? $('#wrapper-container').offset().top : 0,
				menuH = $header.outerHeight(),
				navH = $navigation.outerHeight(),
				latestScroll = 0,
				startFixed = 2;

			if ($header.length) {
				$header.css({
					'padding-bottom': navH
				});
				$navigation.css({
					'top': $header_v1.outerHeight()
				});
			}

			$(window).scroll(function () {
				var current = $(this).scrollTop();
				if (current > menuH) {
					$header.removeClass('affix-top').addClass('affix').removeClass('menu-show');
					$header.css({
						'padding-bottom': 0
					});
					$navigation.css({
						top: off_Top
					});
				} else {
					$header.removeClass('affix').addClass('affix-top').addClass('no-transition');
					$header.css({
						'padding-bottom': navH
					});
					$navigation.css({
						top: $header_v1.outerHeight()
					});
				}
				//
				// if (current > latestScroll && current > menuH * 2) {
				// 	if (!$header.hasClass('menu-hidden')) {
				// 		$header.addClass('menu-hidden').removeClass('menu-show').removeClass('no-transition');
				// 		$navigation.css({
				// 			top: off_Top
				// 		});
				// 	}
				// } else {
				// 	if ($header.hasClass('menu-hidden')) {
				// 		$header.removeClass('menu-hidden').addClass('menu-show');
				// 		$navigation.css({
				// 			top: off_Top
				// 		});
				// 	}
				// }

				latestScroll = current;
			});

			$('#masthead .navbar > .menu-item-has-children, .navbar > li ul li').hover(
				function () {
					$(this).children('.sub-menu').stop(true, false).slideDown(250);
				},
				function () {
					$(this).children('.sub-menu').stop(true, false).slideUp(250);
				}
			);
		},

		/**
		 * Back to top
		 */
		back_to_top: function () {
			var $element = $('#back-to-top');
			$(window).scroll(function () {
				if ($(this).scrollTop() > 100) {
					$element.addClass('scrolldown').removeClass('scrollup');
				} else {
					$element.addClass('scrollup').removeClass('scrolldown');
				}
			});

			$element.on('click', function () {
				$('html,body').animate({scrollTop: '0px'}, 800);
				return false;
			});
		},

		/**
		 * Sticky sidebar
		 */
		sticky_sidebar: function () {
			var offsetTop = 20;

			if ($("#wpadminbar").length) {
				offsetTop += $("#wpadminbar").outerHeight();
			}
			if ($("#masthead.affix").length) {
				offsetTop += $("#masthead.affix").outerHeight();
			}

			if ($('.sticky-sidebar').length > 0) {
				$(".sticky-sidebar").theiaStickySidebar({
					"containerSelector"     : "",
					"additionalMarginTop"   : offsetTop,
					"additionalMarginBottom": "0",
					"updateSidebarHeight"   : false,
					"minWidth"              : "768",
					"sidebarBehavior"       : "modern"
				});
			}
		},

		/**
		 * Parallax init
		 */
		parallax: function () {
			$(window).stellar({
				horizontalOffset: 0,
				verticalOffset  : 0
			});
		},

		/**
		 * feature: Preloading
		 */
		feature_preloading: function () {
			var $preload = $('#thim-preloading');
			if ($preload.length > 0) {
				$preload.fadeOut(1000, function () {
					$preload.remove();
				});
			}
		},


		/**
		 * Custom select use Dropkickjs
		 */
		custom_select: function () {
			$('select').select2({
				minimumResultsForSearch: Infinity
			});
		},


		/**
		 * Custom effect and UX for contact form.
		 */
		contactform7: function () {
			$(".wpcf7-submit").on('click', function () {
				$(this).css("opacity", 0.2);
				$(this).parents('.wpcf7-form').addClass('processing');
				$('input:not([type=submit]), textarea').attr('style', '');
			});

			$(document).on('spam.wpcf7', function () {
				$(".wpcf7-submit").css("opacity", 1);
				$('.wpcf7-form').removeClass('processing');
			});

			$(document).on('invalid.wpcf7', function () {
				$(".wpcf7-submit").css("opacity", 1);
				$('.wpcf7-form').removeClass('processing');
			});

			$(document).on('mailsent.wpcf7', function () {
				$(".wpcf7-submit").css("opacity", 1);
				$('.wpcf7-form').removeClass('processing');
			});

			$(document).on('mailfailed.wpcf7', function () {
				$(".wpcf7-submit").css("opacity", 1);
				$('.wpcf7-form').removeClass('processing');
			});

			$('body').on('click', 'input:not([type=submit]).wpcf7-not-valid, textarea.wpcf7-not-valid', function () {
				$(this).removeClass('wpcf7-not-valid');
			});
		},


		/**
		 * Blog auto height
		 */
		blog_auto_height: function () {
			var $blog = $('.archive .blog-content article'),
				maxHeight = 0,
				setH = true;

			// Get max height of all items.
			$blog.each(function () {
				setH = $(this).hasClass('column-1') ? false : true;
				if (maxHeight < $(this).find('.content-inner').height()) {
					maxHeight = $(this).find('.content-inner').height();
				}
			});

			// Set height for all items.
			if (maxHeight > 0 && setH) {
				$blog.each(function () {
					$(this).find('.content-inner').css('height', maxHeight);
				});
			}
		},

		/**
		 * Widget Masonry
		 */
		widget_masonry: function () {
			$('.masonry-items').imagesLoaded(function () {
				var $grid = $('.masonry-items').isotope({
					percentPosition: true,
					itemSelector   : 'article',
					masonry        : {
						columnWidth: 'article'
					}
				});

				$('.masonry-filter a').removeClass('active');
				$('.masonry-filter a:first-child').addClass('current');

				$('.masonry-filter').on('click', 'a', function () {
					$('.masonry-filter a').removeClass('current');
					$(this).addClass('current');
					var filterValue = $(this).attr('data-filter');
					$grid.isotope({filter: filterValue});
				});
			});
		},


		widget_brands: function () {
			var rtl = false;
			if ($('body').hasClass('rtl')) {
				rtl = true;
			}

			$(".thim-brands").each(function () {
				var items = parseInt($(this).attr('data-items'));
				$(this).owlCarousel({
					nav       : false,
					dots      : false,
					rtl       : rtl,
					responsive: {
						0   : {
							items: 2,
						},
						480 : {
							items: 3,
						},
						768 : {
							items: 4,
						},
						1024: {
							items: items,
						}
					}
				});
			});
		},

	};

})(jQuery);